.chat-grid {
    background: #FFFFFF;
    height: 100%;
    width: 90%;
    border-radius: 10px;
    overflow: hidden;
    overflow-y: scroll;
}

.partner-card {
    box-shadow: none;
    background: #F8F9F9;
    border-radius: 15px 15px 0px 15px;
    width: 80%;
    float: right;
    margin: 5px;
}

.partner-card-content {
    font-family: 'Poppins';
    line-height: 1.2;
    color: #333333;
}

.chat-icon {
    font-size: 50px;
}

.admin-card {
    margin: 5px;
    width: 80%;
    border: 1px solid #128777;
    box-shadow: none;
    border-radius: 15px 15px 15px 0px;
}

.admin-date-label{
    font-size: 10px;
    margin: 0px 0px 0px 5px;
}

.partner-date-label{
    font-size: 10px;
    margin: 5px 3% 0px 20%;
    float: right;
    line-height: 1.2;
}