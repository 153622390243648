.dash-main-title {
    font-family: 'Poppins';
    font-size: 20px;

    letter-spacing: 0.4px;
    color: #fff;
    text-align: left;
}

.dash-title {
    font-family: 'Poppins';
    font-size: 20px;

    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #fff;
}

.CircularProgressbar-path {
    transform: rotate(0.5turn) !important;
}

.unread-badge {
    border-radius: 7px;
    --padding-bottom: 3px;
    background: #FFFFFF;
    color: #16C1D0;
    --padding-top: 4px;
    margin-left: 5%;
    padding: 4px 5px 3px;
}

.header-toolbar {
    background: #128777;
}

.unread-div {
    display: flex;
    align-items: center;
    padding: 8px 10px;
}

.unread-img {
    position: absolute;
    right: 15px;
}

.unread-msg {
    font-family: 'Poppins';
    font-size: 14px;

    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #FFFFFF;
}

.header-logo {
    width: 47%;
    margin-left: 2vh;
}

.bell-icon-m {
    padding: 0;
    margin: 0 !important;
}

.table-m-title {
    margin: 2.2vh !important;
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
}

.table-main-title {
    font-size: 18px !important;
}

.right-reject-count {
    position: absolute;
    right: 4%;
}

.overall-pounds {
    font-size: 15px;
    line-height: 17px;
    font-family: 'Poppins';

    letter-spacing: 0.4px;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin: 3px 0px -2px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.small-title {
    font-size: 12px;
    line-height: 17px;
    font-family: 'Poppins';

    letter-spacing: 0.4px;
    color: #128777;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    border: 8px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.upcoming-arrow-btn {
    position: absolute;
    right: 0px;
}

.upcoming-arrow-icon {
    font-size: 33px !important;
}

.overall-title {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #333333;
    letter-spacing: 0.4px;
    text-align: left;
}

.overall-title-cat {
    font-family: 'Poppins';
    font-size: 14px;

    line-height: 22px;
    color: #000000;
    letter-spacing: 0.4px;
    text-align: left;
}

.total-div {
    display: flex;
    align-items: center;
}

.total-label {
    margin-left: 2%;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #515151;
}

.grid-p {
    padding: 5px;
}

.revenue-div {
    background: #16C1D0;
    color: #FFFFFF;
    width: fit-content;
    padding: 5px;
    border-radius: 12px;
}

.month-label {
    font-family: 'Poppins';
    font-size: 16px;

    color: #515151;
}

.home-toolbar {
    --border-width: 0;
    --background: transparent !important;
    --color: white !important;
    position: absolute;
    width: 100%;
}

#senicn {
    font-size: 30px !important;
}

.home-toolbar ion-title {
    margin-left: 60px;
}

.margin-top {
    margin-top: 38%;
}

.home {
    --background: #f8f9f9 !important;
}

ion-footer {
    --background: #f8f9f9 !important;
}

.background-holder {
    background: #128777;
    border-radius: 0 0 32px 32px;
    height: 165px;
    max-height: 100%;
}

.first-top-card {
    padding: 0px 10px;
    padding-left: 0vh;
    top: 65px;
    width: 100%;
    position: absolute;
}

.top-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 100%;
    padding: 0px;
}

.second-top-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 100%;
    padding: 0px;
}

.booking-sts-card {
    background: #FFFFFF;
    border-radius: 12px;
    width: 90%;
    margin: auto;
    box-shadow: 0px 5px 20px 0px #0000001A;
    padding: 2px;
}

.top-grid {
    position: absolute;
    top: 60px;
    margin: auto;
    width: 100%;
}

.booking-sts-title {
    font-family: 'Poppins';
    font-size: 18px;
    color: #515151;

    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: left;
}

.booking-sts-div {
    display: flex;
    align-items: center;
}

.green-square {
    height: 13px;
    width: 13px;
    margin-left: 5%;
    border-radius: 3px;
    background-color: #128777;
}

.yellow-square {
    height: 13px;
    width: 13px;
    margin-left: 5%;
    border-radius: 3px;
    background-color: #FFD600;
}

.info-title {
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 20px;
    letter-spacing: 0.4px;
    color: #515151;
    text-align: left;
}

.info-msg {
    font-family: 'Poppins';
    font-size: 13px;
    margin-top: 2%;
    font-weight: 500;
    line-height: 15px;
    color: #515151;
    letter-spacing: 0.4px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.reject-title {
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 20px;
    letter-spacing: 0.4px;
    color: #fff;
    margin-left: 15px;
    text-align: left;
}

.reject-msg {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    letter-spacing: 0.4px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.missing-title {
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 20px;
    letter-spacing: 0.4px;
    margin-left: 15px;
    color: #fff;
    text-align: left;
}

.missing-msg {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    letter-spacing: 0.4px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.top-card .top-row p {
    color: #77869E;
    font-family: 'Roman';
    font-size: 13px;

}

.total-label-m {
    font-family: 'Poppins';
    font-size: 14px;

    margin-bottom: 4%;
    display: block;
    color: #515151;
}

.revenue-label {
    font-family: 'Poppins';
    font-size: 14px;

    display: block;
    margin-top: 10%;
    margin-bottom: 4%;
    color: #515151;
}

.info-grid {
    border: 1px solid #16C1D0;
    width: 94%;
    margin: auto;
    border-radius: 10px;
    margin-top: 4%;
    padding: 5px;
    margin-bottom: 2%;
}

.reject-booking-grid {
    box-shadow: 0px 5px 20px 0px #0000001A;
    background: #FF6666;
    color: #fff;
    width: 90%;
    margin: auto;
    margin-top: 5%;
    border-radius: 12px;
}

.dash-td {
    padding: 10px;
}

.dash-td-title {
    font-family: 'Poppins';
    font-size: 14px;

    line-height: 15px;
    letter-spacing: 0.4px;
    color: #FFFFFF !important;
}

.table-dash {
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 12px;
    margin-top: 3%;
}

.missing-image-grid {
    background-color: #729196;
    box-shadow: 0px 5px 20px 0px #0000001A;
    color: #fff;
    width: 90%;
    border-radius: 12px;
    margin-bottom: 3vh !important;
}

.icon-btn-bell {
    --padding-end: 12px !important;
}

.right-arrow {
    position: absolute;
    right: 45%;
}

.info-img {
    width: 90%;
    margin-left: 10%;
}

.top-card .top-row p strong {
    color: #042C5C;
    font-family: 'Heavy' !important;
}

.top-card .top-row ion-button {
    font-family: 'Heavy' !important;
}

.precentage-row {
    margin: 8px 0px 0px 35px;
}

.home .bottom-section {
    padding: 0 16px;
}

.home .bottom-section .btns-holder ion-button {
    --background: #dfe7f5;
    --border-radius: 12px !important;
    --box-shadow: none;
    --color: #0047cc;
    height: 50px !important;

}

.home .bottom-section .btns-holder ion-button ion-icon {
    font-size: 25px;

}

.progressbar-holder .progress-image {
    height: 30px;
}

.progressbar-holder .spent-holder {
    color: "#77869E";
    font-size: 13px;
    margin-top: 10px;

}

.progressbar-holder .bottom-detail {
    margin-top: 50px;
}

.bottom-detail p {
    font-size: 12px;
}

.details .the-title h3 {
    color: #262626;
    font-family: 'Heavy';
    font-size: 14px;
}

.the-list ion-item {
    --border-radius: 10px;
    margin: 10px 0 !important;
}

.the-list ion-item .start-slot {
    margin-right: 16px;
}

.the-list ion-item .start-slot img {
    height: 40px;
}

.the-list ion-item h5 {
    color: #ee5a55 !important;
    font-family: 'Heavy' !important;
}




.the-list ion-item ion-label h4 {
    color: #042C5C !important;
    font-family: 'Heavy' !important;
    font-size: 16px;
}

.the-list ion-item ion-label p {
    color: #77869E !important;
    font-family: 'Roman' !important;
    font-size: 13px;
    margin: 0 !important;
    padding-bottom: 0 !important;
}

.split-up-heading {
    border-radius: 12px 0px 0px;
    background: rgb(22, 193, 208) !important;
    font-family: 'poppins' !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #fff !important;
    padding: 12px;
}

.revenue-heading {
    border-radius: 0px !important;
    background: rgb(22, 193, 208) !important;
    font-family: 'poppins' !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    padding: 12px;
    color: #fff !important;
}

.bkng-count {
    border-radius: 0px 12px 0px 0px !important;
    background: rgb(22, 193, 208) !important;
    padding: 12px;
    font-family: 'poppins' !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: rgb(255, 255, 255) !important;
}




/* Dark Mode CSS Starts */

.dark-home {
    --background: #212124 !important;
}

.dark-top-card {
    background: #262626;
    border-radius: 12px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    bottom: -40px;
    left: 5%;
    width: 90%;
    position: absolute;
    padding: 0px;
}

.dark-overall-title {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #CCCCCC;
    letter-spacing: 0.4px;
    text-align: left;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}

.dark-overall-pounds {
    font-family: 'Poppins';

    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #CCCCCC;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    border: 8px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dark-booking-sts-card {
    background: #262626;
    border-radius: 12px;
    width: 90%;
    margin: auto;
    box-shadow: 0px 5px 20px 0px #0000001A;
    padding: 2px;
}

.dark-booking-sts-title {
    font-family: 'Poppins';
    font-size: 18px;
    color: #CCCCCC;

    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: left;
}

.dark-info-title {
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 20px;
    letter-spacing: 0.4px;
    color: #CCCCCC;
    text-align: left;
}

.dark-info-msg {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    margin-top: 2%;
    line-height: 17px;
    color: #CCCCCC;
    letter-spacing: 0.4px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.dark-reject-booking-grid {
    box-shadow: 0px 5px 20px 0px #0000001A;
    background: #FF6666;
    color: #fff;
    width: 90%;
    margin: auto;
    margin-top: 5%;
    border-radius: 12px;
}

.dark-dash-main-title {
    font-family: 'Poppins';
    font-size: 20px;

    line-height: 22px;
    letter-spacing: 0.4px;
    color: #CCCCCC;
    text-align: left;
}

.dark-table td:nth-child(1) {
    color: #CCCCCC;
    background: #D9D9D908;
}

.dark-table td:nth-child(2) {
    color: #CCCCCC;
    background: #D9D9D908;
}

.dark-table td:nth-child(3) {
    color: #CCCCCC;
    background: #D9D9D908;
}

.dark-table tr:nth-child(even) {
    background-color: #262626;
}

.dark-table {
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 12px;
    margin-top: 3%;
}

.dark-month-label {
    font-family: 'Poppins';
    font-size: 16px;

    color: #CCCCCC;
}

.dark-total-label-m {
    font-family: 'Poppins';
    font-size: 14px;

    margin-bottom: 4%;
    display: block;
    color: #CCCCCC;
}

.dark-total-label {
    margin-left: 2%;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #CCCCCC;
}

.monthly-main-title {
    font-size: 16px !important;
}

.dark-revenue-label {
    font-family: 'Poppins';
    font-size: 14px;

    display: block;
    margin-top: 10%;
    margin-bottom: 4%;
    color: #CCCCCC;
}

.dark-total-label-badge {
    margin-left: 2%;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #FFF;
}

.dark-revenue-div {
    background: #16C1D0;
    color: #FFFFFF;
    width: fit-content;
    padding: 5px;
    border: 2px solid #E8F9FA;
    border-radius: 12px;
}


/* Dark Mode CSS Ends */





.no-network-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-network-img {
    width: 60%;
}

.active-chip {
    color: #28BA62;
    width: 45%;
    font-family: 'Poppins';
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 4px 3px 3px 3px;
    border-radius: 25px;
    background: #2DBA621A;
}

.inactive-chip {
    color: #28BA62;
    width: 45%;
    font-family: 'Poppins';
    position: absolute;
    font-size: 12px;
    right: 0;
    font-weight: 500;
    text-align: center;
    padding: 4px 3px 3px 3px;
    border-radius: 25px;
    background: #2DBA621A;
}

.dash-view-all {
    --background: #FFFFFF;
    color: #128777 !important;
    min-height: 50px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;

    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #128777;
    border-radius: 10px;
    --border-radius: 12px;
    width: 100%;
    margin: auto;
}


.margin-auto {
    margin: auto;
}

.right-icon-upcoming {
    width: 50px;
    height: 35px;
    right: 5%;
    position: absolute;
}

.upcoming-booking {
    background-color: yellow;
}

.completed-booking {
    background-color: green;
}

.dash-m-title {
    margin: 15px 15px 5px;
    font-size: 15px;
}

.ion-page-visible {
    opacity: 1 !important;
}

.non-clickable {
    pointer-events: none;
    color: inherit;
    /* Optional: maintain the default text color */
}

.non-clickable:hover {
    background: inherit;
    /* Optional: maintain the default background color on hover */
    cursor: not-allowed;
    /* Optional: show a "not-allowed" cursor on hover */
}

.dash-ahs-img {
    margin: auto;
    width: 100%;
    padding: 0px;
}