.reject-btn {
    border: 2px solid #ED1C24;
    --background: #fff;
    color: #D03716 !important;
    border-radius: 12px;
    min-height: 50px;
    box-shadow: none;
    --box-shadow: none;
    --border-radius: 12px;
    font-family: 'Poppins';

}

.in-travel-cost {
    font-size: 12px;
    font-family: 'Poppins';

}

.accept-btn {
    --background: #28BA62;
    background: #28BA62;
    color: #fff !important;
    min-height: 50px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;

    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #28BA62;
    border-radius: 15px;
    --border-radius: 12px;
}

.yes-accept-btn {
    --background: #128777;
    background: #128777;
    color: #fff !important;
    min-height: 50px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;

    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #128777;
    border-radius: 14px;
    --border-radius: 12px;
}

.no-btn {
    --background: #fff;
    border: 2px solid #128777;
    color: #128777 !important;
    border-radius: 12px;
    min-height: 50px;
    box-shadow: none;
    --box-shadow: none;
    --border-radius: 12px;
    font-family: 'Poppins';

}

.yes-reject-btn {
    --background: #ED1C24;
    background: #ED1C24;
    color: #fff !important;
    min-height: 50px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;

    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #ED1C24;
    border-radius: 14px;
    --border-radius: 12px;
}

.accordion-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
}

.accordion-content.show {
    max-height: unset;
    transition: max-height 0.3s ease-in;
    width: 95%;
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 10px;
    margin: 2% 0% 2% 2%;
}

/* .status3-card {
    width: 95%;
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 10px;
    margin: auto;
} */

ion-item {
    margin-top: 2%
}

.btn-row {
    padding: 10px 12px;
}

.btn-grid {
    padding: 0;
    margin: 0;
}

.details-title {
    font-family: 'PoppinsSemiBold' !important;
    padding: 20px;
}

.payment-details {
    font-family: 'PoppinsSemiBold'; 
}

.booking-title-top {
    padding-inline-start: 20px;
    margin-top: 10px;
}

.back-btn {
    margin: 0;
}

.first-col {
    border-bottom: 1px solid #E6E6E6CC;
    padding: 10px;
}

.sec-col {
    border-bottom: 1px solid #E6E6E6CC;
    padding: 10px;
}

.payment-details-card {
    border-radius: 10px;
    background: #fff;
    margin: 5px 10px 30px;
    box-shadow: 0px 0px 6px 0px #00000026;
    border-bottom: 4px solid #128777;
}

.font-color {
    color: #515151;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
}

.p-details-r {
    border-top: 1px solid #F0F0F0;
}

.p-details-label {
    float: right;
}

.service-d-title {
    padding: 0px 0px 4px 0px;
    --background: #fff0;
}

.no-image-vd {
    width: 90%;
    height: 90%;
    margin: auto;
    object-fit: cover;
}

.p-details-last-col {
    display: flex;
    align-items: center;
    justify-content: end;
}

.pickup-btn {
    --background: #128777;
    color: #fff;
    min-height: 50px;
    box-shadow: none;
    --box-shadow: none;
    --border-radius: 12px;
    border-radius: 12px;
    padding: 4px 4px 20px;
}

.value-color {
    color: #262626;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
}

ion-modal#example-modal {
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --width: 94%;
    --height: 350px;
    --max-height: 100%;
}

ion-modal#example-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#example-modal ion-toolbar {
    --background: #F8F9F9;
    --color: white;
    padding: 0;
}




ion-modal#example-modal-reject {
    --height: 700px;
    --width: 95%;
    --max-height: 100%;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#example-modal-reject::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#example-modal-reject ion-toolbar {
    --background: #F8F9F9;
    --color: white;
    padding: 0;
}

.reject-booking-id {
    background: #ED1C241A;
    padding: 4px 10px 4px 10px;
    border-radius: 12px;
    color: #ED1C24;
    font-size: 13px;
    font-family: 'Poppins';
    float: left;
}

.reject-label-div {
    display: flex;
    justify-content: left;
}

.reject-f {
    font-size: 16px;
}


ion-modal#example-modal-confirmed {
    --max-height: 100%;
    --height: 300px;
    --width: 78%;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#example-modal-confirmed::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#example-modal-confirmed ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
}


ion-modal#example-modal-inactive {
    --max-height: 100%;
    --height: 350px;
    --width: 90%;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#example-modal-inactive::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#example-modal-inactive ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
}




.btn-icon {
    font-size: 17px !important;
    margin: 0px 9px 0px 0px;
}

.order-span {
    order: 2;
    font-size: 16px;
}

.modified-chip {
    background-color: #EF69541A;
    color: #EF6954;
    width: 30%;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding: 4px 3px 3px;
    border-radius: 20px;
    margin-left: 2%;
}

.unconfirmed-chip {
    background-color: #FFD60033;
    color: #333333;

    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding: 4px 10px;
    border-radius: 20px;
    margin-left: 2%;
}

.label-div {
    display: flex;
    justify-content: center;
}

.expired-chip {
    background-color: #ED1C241A;
    color: #ED1C24;
    width: 30%;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding: 4px 3px 3px;
    border-radius: 20px;
    margin-left: 2%;
}

.confirmed-chip {
    background-color: #28BA62;
    color: #FFFFFF;
    width: 30%;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding: 4px 3px 3px;
    border-radius: 20px;
    margin-left: 2%;
}

.balance-pay {
    font-family: 'Poppins';
    font-size: 16px;

    line-height: 10px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #262626;
}

.location-div {
    background: #FFFFFF;
    text-align: center;
    border-radius: 6px;
}

.location-text {
    text-decoration: underline;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #515151;
}

.location-btn {
    --border-radius: 12px;
    min-height: 45px;
    width: 100% !important;
}

.call-btn {
    min-height: 45px;
    --border-radius: 7px;
    width: 100% !important;
    border: 2px solid #128777;
}

.compass-img {
    margin: 0px 5px 0px -15px;
}

.compass-dp-lcn {
    margin: 0px 5px 0px -28px;
}

.model-bg {
    --background: #fff;
}

.model-title {
    font-size: 15px;
    color: #515151;
    text-align: center;
    margin: 10px;
}

.model-sub-title {
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #000000;
}

.reject-sentence {
    color: #333333;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
    text-align: center;
}

.model-sub-div {
    padding: 2px;
    text-align: center;
}

.model-container {
    position: relative;
    margin: auto;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    width: 97%;
}

.form-control-model {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    background-color: #fff;
    color: #262626;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: left;
    min-height: 25px;
}

.label-outline-model {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
    margin: -7px 0px -10px 13px;
    transition: font-size 0.2s;
    color: #128777;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
}

.model-note {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    display: block;
    color: #333333;
}

.checkbx-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbx-label {
    margin-left: 4%;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #A8A8A8;
}

.checkbx-span {
    color: #128777;
}

.reject-accept-button {
    min-height: 50px !important;
}

.emailErrorMsg {
    font-size: 12px;
    text-align: center;
    color: #ff0000 !important;
    width: 90%;
    display: block;
    margin: auto;
}

.tick-img {
    width: 25%;
    height: unset;
    margin: auto;
}

.b-cnfmed-title {
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 34px;
    display: block;
    letter-spacing: 0.4px;
    text-align: center;
}

.thanks-cnfmed-title {
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 500;
    display: block;
    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: center;
}

.bookingid-label {

    font-family: 'Poppins';
    font-size: 16px;
}



/* Dark Mode CSS starts */

.dark-bookingid-label {
    color: #CCCCCC;
    font-family: 'Poppins';
    font-size: 16px;


}

.dark-reject-btn {
    border: 1px solid #404040;
    --background: #212124;
    color: #D03716;
    border-radius: 12px;
    min-height: 53px;
    box-shadow: none;
    --box-shadow: none;
    --border-radius: 12px;
}

.dark-service-d-title {
    padding: 0px 0px 4px 0px;
    --background: #212124;
    color: #CCCCCC;
}

.dark-arrowIcon {
    color: #CCCCCC;
}

.dark-first-col {
    background: #262626;
    border-bottom: 1px solid #D9D9D908;
    padding: 10px;
}

.dark-font-color {
    color: #A3A3A3;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
}

.dark-value-color {
    color: #CCCCCC;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
}

.dark-sec-col {
    border-bottom: 1px solid #D9D9D908;
    padding: 10px;
}

.dark-payment-details {
    font-family: 'Poppins';
    font-size: 16px;

    text-align: left;
    color: #CCCCCC;
}

.dark-payment-details-card {
    margin: 3px 15px;
    box-shadow: 0px 0px 6px 0px #00000026;
    border-radius: 10px;
    background: #262626;
}

.dark-p-details-r {
    border-top: 1px solid #D9D9D908;
}

.dark-balance-pay {
    font-family: 'Poppins';
    font-size: 16px;

    line-height: 10px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #CCCCCC;
}

.dark-location-div {
    background: #303030;
    text-align: center;
    border-radius: 6px;
}

.dark-location-text {
    text-decoration: underline;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #A3A3A3;
}

ion-modal#dark-example-modal ion-toolbar {
    --background: #515151;
    --color: white;
    padding: 0;
}

ion-modal#dark-example-modal {
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --width: 94%;
    --height: 380px;
    --max-height: 100%;
}

ion-modal#dark-example-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#dark-example-modal ion-toolbar {
    --background: #515151;
    --color: white;
    padding: 0;
}




ion-modal#dark-example-modal-reject {
    --height: 540px;
    --width: 94%;
    --max-height: 100%;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#dark-example-modal-reject::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#dark-example-modal-reject ion-toolbar {
    --background: #515151;
    --color: white;
    padding: 0;
}

ion-modal#dark-example-modal-confirmed {
    --max-height: 100%;
    --height: 270px;
    --width: 78%;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#dark-example-modal-confirmed::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#dark-example-modal-confirmed ion-toolbar {
    --background: #515151;
    --color: white;
    padding: 0;
}

.dark-model-bg {
    --background: #262626;
}

.dark-model-title {
    font-size: 16px;

    color: #CCCCCC !important;
}

.popup-modified-chip {
    background-color: #FEF6CD;
    color: #515151;
    width: 27%;
    padding: 4px 3px 3px 3px;
    border-radius: 8px;
    margin-top: -2px;
    font-family: 'Poppins';
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-left: 3%;
}

.popup-confirmed-chip {
    background-color: #529003;
    color: #FFFFFF;
    padding: 4px 3px 3px 3px;
    border-radius: 8px;
    margin-top: -2px;
    width: 27%;
    font-family: 'Poppins';
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-left: 3%;
}

.dark-model-sub-title {
    font-size: 18px;

    line-height: 27px;
    text-align: left;
    color: #CCCCCC !important;
}

.dark-form-control-model {
    width: 100%;
    padding: 10px;
    border: 1px solid #404040;
    border-radius: 10px;
    background-color: #262626;
    color: #CCCCCC !important;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: left;
    min-height: 25px;
}

.dark-label-outline-model {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #262626;
    z-index: 2;
    margin: -5px 0px 0px 15px;
    font-size: 12px;
    transition: font-size 0.2s;
    color: #D9D9D9;
}

.dark-model-note {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    display: block;
    color: #CCCCCC;
}

.dark-reject-btn {
    border: 1px solid #404040;
    --background: #fff0;
    color: #D03716 !important;
    border-radius: 12px;
    min-height: 50px;
    box-shadow: none;
    --box-shadow: none;
    font-size: 13px;
    --border-radius: 12px;
    text-transform: uppercase;
}

.dark-b-cnfmed-title {
    font-size: 18px;

    line-height: 34px;
    display: block;
    text-align: center;
    color: #CCCCCC !important;
}

.dark-thanks-cnfmed-title {
    font-size: 17px;
    font-weight: 500;
    display: block;
    line-height: 27px;
    text-align: center;
    color: #CCCCCC !important;
}


.padding-img {
    padding-inline-start: var(--ion-padding, 0px) !important;
    padding-inline-end: var(--ion-padding, 10px) !important;
}