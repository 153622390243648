.background-img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

ion-content.overflow {
    --offset-top: 0px !important;
    --offset-bottom: 0px !important;
    --keyboard-offset: 0px !important;
}

ion-content.background-img {
    position: relative;
    z-index: 1;
}

.getstarted-title {
    position: relative;
}

.getstarted-msg {
    font-family:  'poppins';
    font-size: 26px;
    font-weight: 900;
    text-align: left;
    color: #FFFFFF;
}

.letsgrow-msg {
    font-family:  'poppins';
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #FFF;
}

.afh-logo {
    position: relative;
    display: flex;
    width: 25%;
}

.getstarted-btn {
    --background: #128777;
    --box-shadow: none;
      
    color: #fff;
    font-family:  'poppins';
    --border-radius: 8px;
    min-height: 50px;
    position: fixed;
    bottom: 19px;
    width: 86%;
}




.bg-color {
    background-color: #128777;
}

  
  .gradient-overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg, 
      rgba(0, 0, 0, 0.5) 31.9%, 
      rgba(255, 255, 255, 0.5) 83.4%
    );
    pointer-events: none; /* This makes sure that click events pass through the overlay */
  }
  
  .background-img-container img {
    display: block; /* This ensures there are no extra spaces inside the container */
    width: 100%; /* This makes the image fill the container */
  }


  .m-title-getstarted {
    margin: 15px 10px;
    font-size: 15px;
}