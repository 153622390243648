.dark-title {
    font-family: 'Poppins';
    font-size: 16px;

    line-height: 27px;
    float: left;
    color: #CCCCCC;
    margin-left: 2%;
}

.login-edit-title {
    font-family: 'Poppins';
    font-size: 16px;

    line-height: 27px;
    float: left;
    color: #515151;
    margin-left: 2%;
}

.dark-login-edit-title {
    font-family: 'Poppins';
    font-size: 16px;

    line-height: 27px;
    float: left;
    color: #CCCCCC;
    margin-left: 2%;
}

.v-profile-s-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
}

.dark-v-profile-s-card {
    background: #262626;
    border-radius: 12px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
}

.dark-v-profile-td-card {
    background: #262626;
    border-radius: 12px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
}

.v-profile-td-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
}

.service-cat-chip {
    background-color: #E8F9FA;
    color: #515151;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #16C1D0;
    padding: 5px;
    border-radius: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    margin: auto;
}

.legal-info-label {
    text-align: left !important;
    display: block;
    margin-left: 2%;
}

.mt-0 {
    margin-top: 0% !important;
}

.dark-service-cat-chip {
    background-color: #fff;
    color: #515151;
    width: 90%;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    margin-left: 3%;
    padding: 4px 3px 3px 3px;
    border-radius: 8px;
}

.view-more {
    --box-shadow: none;
    --background: #fff0;
    --color: #515151;
    box-shadow: none;
    background: #fff0;
    color: #515151;
    font-size: 16px;
    margin: 0;
    text-decoration: underline;
}


/* CSS for the dropdown list */


.dropdown-content {
    display: none;
}

.dropdown-content-avail {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 5px 20px 0px #0000001A;
    z-index: 1;
    width: 95%;
    height: 250px;
    overflow-y: auto;
    margin: auto;
}

.dark-view-more {
    --box-shadow: none;
    --background: #fff0;
    --color: #515151;
    box-shadow: none;
    background: #fff0;
    color: #CCCCCC;
    font-size: 16px;
    margin: 0;
    text-decoration: underline;
}

.dropdown-content-avail-null {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 5px 20px 0px #0000001A;
    z-index: 1;
    width: 95%;
    height: 250px;
    overflow-y: auto;
    margin: auto;
}

.dark-dropdown-content-avail-null {
    position: absolute;
    background-color: #262626;
    box-shadow: 0px 5px 20px 0px #0000001A;
    z-index: 1;
    width: 95%;
    height: 250px;
    overflow-y: auto;
    border: 1px solid #404040;
    margin: auto;
    border-radius: 5px;

}

.dark-dropdown-content-avail {
    position: absolute;
    background-color: #262626;
    box-shadow: 0px 5px 20px 0px #0000001A;
    z-index: 1;
    width: 95%;
    height: 250px;
    overflow-y: auto;
    border: 1px solid #404040;
    margin: auto;
    border-radius: 5px;

}

.dark-item {
    --background: #262626;
    color: #CCCCCC;
    border-bottom: 1px solid #404040;
}


.upload-img-avatar {
    --border-radius: 50%;
    width: 100px;
    height: 100px;
}

.upload-row {
    display: flex;
    align-items: center;
}

.upload-top-card {
    background: white;
    border-radius: 12px;
    margin: auto;
    width: 95%;
    padding: 0px;
    box-shadow: 0px 5px 20px 0px #0000001A;
}

.profile-checkbx-div {
    display: flex;
    align-items: center;
    justify-content: start;
}

.note-text {
    background: #128777;
    font-size: 13px;
    margin-top: -8px;
    color: #FFFFFF;
    padding: 8px 0px 1px 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.save-btn-profile {
    --border-radius: 12px;
    min-height: 50px;
    width: 98%;
    --background: #128777;
    --color: #fff;
    font-size: 16px;
    margin: auto;
    --box-shadow: none;
    border-radius: 12px;
}

.checkbox-title {
    color: #128777;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    display: block;
}

ion-checkbox {
    --background-checked: #128777;
    --border-color-checked: #128777;
    --checkbox-background-checked: #128777;
    --border-radius: 30%;
    border: 2px solid #E6E6E6CC;
    border-radius: 30%;
}


input::-webkit-date-and-time-value {
    text-align: left !important;
}

.form-control-model-date {
    -webkit-appearance: none;
}

.form-control-model-date {
    cursor: pointer;
}

/* Ensure the input is on top of any pseudo-elements */
.form-control-model-date::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

/* For other browsers that support input pseudo-elements */
.form-control-model-date::-ms-clear,
.form-control-model-date::-ms-reveal {
    display: none;
}

.date-icon {
    top: 25px !important;
}