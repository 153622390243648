@font-face {
    font-family: 'Poppins';
    src: url('/public/assets/imgs/images/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('/public/assets/imgs/images/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


body ion-content {
    font-family: 'Poppins';
    letter-spacing: 0.3px;
}

body {
    font-family: 'Poppins', sans-serif;
    /* Fallback to sans-serif if Poppins is not available */
}

strong {
    font-weight: bold;
    /* Will use Poppins-Bold.ttf */
}

.font-name-bold {
    font-family: 'PoppinsSemiBold';
    letter-spacing: 0.3px;
}

em {
    font-style: italic;
    /* Will use Poppins-Italic.ttf */
}

.primary-button {
    --background: #128777;
    background: #128777;
    --color: #fff;
    --box-shadow: none;
    border-radius: 12px;
}

.secondary-button {
    --background: #FFFFFF;
    border: 2px solid #128777;
    --color: #128777;
    --box-shadow: none;
    border-radius: 12px;
}

input:focus {
    outline: none;
}

.sc-ion-input-ios-h:not(.legacy-input) {
    min-height: 33px;
}

.ios ion-input {
    padding: 5px !important;
}

ion-button:hover {
    --background-hover: unset;
}

ion-button:focus {
    --background-activated: unset;
    --background-hover: unset;
    --background-focused: unset;
}

ion-button {
    --border-radius: 0px;
    --background-activated: unset;
    --background-hover: unset;
    --background-focused: unset;
}

.text-area-css:focus {
    outline: none;
}


.ios ion-tab-bar {
    height: 57px !important;
    bottom: 35px;
    padding: 11px;
}

.ios ion-item.styleS {
    --color: #fff;
}

.md .ios .select-icon {
    margin-inline: 4px 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: absolute !important;
    right: 1% !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
    top: 8px !important;
}

ion-input input textarea ion-textarea {
    scroll-behavior: smooth;
}


.searchbar-input.sc-ion-searchbar-md {
    padding-inline-end: 28px;
}




/* Dark Mode CSS Starts */

.dark-mode-tabs .tab-selected {
    --background: #212124 !important;
    --tab-background: var(--tab-background-dark);
    --tab-text-color: var(--tab-text-color-dark);
    color: #CCCCCC !important;
}

/* Apply the styles to IonTabBar and its components */
.dark-mode-tabs ion-tab-bar {
    --background: #212124 !important;
    background-color: var(--tab-background);
    color: var(--tab-text-color);
}

.dark-mode-header-toolbar {
    --background: #212124 !important;
}

.dark-mode-ion-content {
    --background: #212124 !important;
}

.dark-fontName {
    font-family: 'Poppins';
    color: #CCCCCC;
    letter-spacing: 0.3px;
}

/* Dark Mode CSS Ends */


/* Light Mode CSS Starts */

.light-mode-tabs {
    --tab-background: var(--tab-background-light);
    --tab-text-color: var(--tab-text-color-light);
}

.light-mode-tabs ion-tab-bar {
    background-color: var(--tab-background);
    color: var(--tab-text-color);
}


.light-mode-header-toolbar {
    --background: #f8f9f9 !important;
}

.light-mode-ion-content {
    --background: #f8f9f9 !important;
}

.fontName {
    font-family: 'Poppins';
    letter-spacing: 0.3px;
}

/* Light Mode CSS Ends */


.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    --background: transparent !important;
    box-shadow: none !important;
}

.custom-loading .loading-wrapper .loading-spinner {
    font-size: 48px;
    /* --color: #16C1D0 !important; */
}

.sc-ion-buttons-md-s .button-clear {
    --background-focused: none !important;
    --background-hover: none !important;
}

ion-select {
    --highlight-color-focused: none;
}

.text-area-focus:focus {
    outline: none;
}



@media (min-width: 576px) {
    .no-image-vd {
        width: 90%;
        height: 290px;
        margin: auto;
        object-fit: cover;
    }

    .header-logo {
        width: 35%;
        margin-left: 2vh;
    }

    .dark-mode-bg {
        --background: #F8F9F9;
        margin-top: 6% !important;
    }

    .dark-item-mode-bg {
        --background: #212124;
        margin-top: 6% !important;
    }

    .dark-top-card {
        background: #262626;
        border-radius: 12px;
        box-shadow: 0px 0px 20px 0px #00000026;
        bottom: -90px;
        left: 5%;
        width: 90%;
        position: absolute;
        padding: 0px;
    }
}

@media (max-width: 600px) {
    .or_line span {
        display: none !important;
    }

    .landscape-background-img-container {
        display: none;
    }

    .login-landscape-image {
        display: none;
    }

    .m-d-none {
        display: none;
    }

    ion-modal#example-modal {
        --width: 94%;
        --max-height: 400px;
    }
}

.bc-to-login {
    width: 100%;
    margin: auto;
}

.card-success {
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 95%;
    margin: auto;
    border-radius: 10px;
    padding: 10px;
}

@media (min-width: 768px) and (max-width: 1199px) {

    ion-modal#example-modal-deleted {
        --height: 455px;
    }

    .unavail-btn {
        width: 30%;
        right: 15px;
        position: absolute;
        top: -15px;
    }

    .text-ellipis {
        width: 500px;
    }

    .empty-div {
        margin-top: 2% !important;
    }

    .margin-top {
        margin-top: 7%;
    }

    .header-logo {
        width: 25%;
    }

    .d-first-div {
        padding: 9px 0px 0px 55px;
    }

    .t-d-none {
        display: none;
    }

    .style-title-div {
        padding: 11px 0 0 40px;
    }

    .label-outline-model-fp-login {
        margin: -7px 0px -10px 40px;
    }

    .eye-icon {
        right: 35px;
    }

}

.product-image-add {
    margin-top: 4%;
}

@media (min-width: 1200px) {

    .row-avail {
        display: flex;
        align-items: center;
    }

    .service-dot-icon {
        width: 2%;
        margin-left: 1%;
    }

    .serices-title {
        margin-left: 1% !important;
    }

    .no-service-img {
        width: 100%;
        height: 100%;
    }

    .partner-date-label {
        font-size: 10px;
        margin: 5px 5px 0px 20%;
        float: right;
        line-height: 1.2;
    }

    .details-title {
        line-height: 3;
    }

    .avail-icon {
        width: 15%;
        cursor: pointer;
    }

    .react-calendar {
        line-height: 3.8 !important;
    }

    .product-image-add {
        margin-top: 2%;
    }


    ion-modal#example-modal-deleted {
        --height: 400px;
        --width: 60%;
    }

    .card-404 {
        width: 90%;
    }

    .tick-img {
        width: 20%;
    }

    .no-network-img {
        width: 30%;
    }

    ion-modal#example-modal-inactive {
        --max-height: 100%;
        --height: 380px;
        --width: 40%;
        --border-radius: 30px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    ion-modal#example-modal-image-view {
        --max-height: 100%;
        --height: 655px;
        --width: 40%;
        --border-radius: 30px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .card-success {
        width: 50%;
        padding: 50px;
    }

    .bc-to-login {
        width: 30%;
        margin: auto;
    }

    .expired-chip {
        width: 10%;
    }

    ion-modal#example-modal,
    ion-modal#example-modal-confirmed {
        --width: 60%;
        --height: 400px;
    }

    ion-modal#example-modal-reject {
        --height: 600px;
        --width: 50%;
    }

    .accept-grid {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
    }

    .profile-checkbx-div {
        display: flex;
        align-items: center;
        justify-content: start;
    }


    .logout-card {
        margin-top: 3%;
    }

    .center-grid {
        width: 80%;
        margin: auto;
    }

    .center-header {
        width: 80%;
        margin: auto;
    }

    .label-outline-model-fp {
        background-color: #fff;
    }

    .call-icon {
        margin: unset;
        float: right;
        position: absolute;
        right: 4px;
        top: 4px;
    }

    .d-d-block {
        display: block;
    }

    .cat-person-icon {
        width: 3%;
    }

    .d-first-div {
        padding: 9px 0px 0px 55px;
    }

    .select-title {
        margin-left: 3%;
    }



    .slots-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .d-d-none {
        display: none;
    }

    .style-title-div {
        padding: 3px 0px 0px 35px;
    }

    .user-mail {
        margin: 10px 0px;
    }

    .user-name {
        margin-top: 1%;
    }

    .web-fp {
        position: relative;
        top: 50%;
        left: 75%;
        padding: 15px 0px 25px;
        transform: translate(-50%, -50%);
        width: 30%;
        box-shadow: 0px 5px 20px 0px #0000001A;
        background-color: #fff;
        border-radius: 25px;
    }

    .model-container-signup {
        width: 99%;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .btn-holder-signup {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .form-control-model-fp-signup {
        padding: 10px 45px 10px 13px;
    }

    .windows .form-control-model-fp-signup {
        font-size: 13px;
        padding: 8px 45px 8px 13px;
    }

    .windows .model-container-signup,
    .windows .model-container {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .windows .checkbx-label,
    .windows .btn-holder .login-button {
        font-size: 14px;
    }

    .windows .create-account {
        padding: 10px 10px 5px;
        font-size: 14px;
    }

    .windows .eye-icon-signup {
        top: 20px;
    }

    .eye-icon-signup {
        top: 25px;
    }

    .signup-toolbar {
        padding: 5px 20px;
    }

    .the-form.signup-grid {
        position: relative;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
        width: 35%;
        box-shadow: 0px 5px 20px 0px #0000001A;
        background-color: #fff;
        border-radius: 25px;
    }

    .label-outline-model-fp-signup {
        background-color: #fff;
    }

    .bg-signup {
        background-image: url('../public/assets/imgs/images/gd-signup-image.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .bg-fp {
        background-image: url('../public/assets/imgs/images/gd-password-image.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .background-img-container-login {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .login-card-container {
        top: 50%;
        left: 75%;
        bottom: unset;
        width: 35%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
    }



    .login-img {
        display: none;
    }


    .getStarted-grid {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 100%;
    }

    .getStarted-col {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .getstarted-btn {
        --background: #128777;
        --box-shadow: none;

        color: #fff;
        font-family: 'Poppins';
        --border-radius: 8px;
        min-height: 50px;
        position: unset;
        width: 100%;
    }



    .dark-login-card-container {
        padding: 10px;
    }

    .dark-afh-logo {
        width: 100px;
    }

    .dark-input-label {
        margin: 0px 15px;
    }

    .dark-login-ion-item {
        width: 98%;
    }

    .dark-rem-holder {
        width: 98%;
    }

    .dark-login-button {
        margin: auto;
    }

    .dark-header-logo {
        width: 200px;
        margin-left: 2vh;
    }

    .dark-top-card {
        bottom: -50px;
        left: 1%;
        width: 98%;
    }

    .login-card-container {
        padding: 10px;
    }

    .afh-logo {
        width: 100px;
    }

    .input-label {
        margin: 0px 15px;
    }

    .login-ion-item {
        width: 98%;
    }

    .rem-holder {
        width: 98%;
    }

    .login-button {
        margin: auto;
    }

    .header-logo {
        width: 200px;
        margin-left: 2vh;
    }


    .all-img {
        max-width: 90px;
    }

    .booking-sts-card {
        width: 98%;
    }

    .dark-booking-sts-card {
        width: 98%;
    }

    .overall-title {
        padding-top: 10px;
    }

    .overall-pounds {
        margin: 8px 0px 5px;
    }

    .dash-card-div {
        margin: 5px 0px 0px;
    }

    .unavail-btn {
        width: 25%;
        justify-content: end;
        display: flex;
        margin: initial;
    }

    .slots-title {
        margin: 25px auto 0px;
        width: 74%;
        display: flex;
        justify-content: space-between;
    }

    .margin-top-unavail {
        margin-top: 2%;
    }

    .text-ellipis {
        width: 500px;
    }

    .first-top-card {
        padding: 0px 10px;
        padding-left: 0vh;
        top: 100px;
        width: 100%;
        position: absolute;
    }

    .info-grid {
        width: 99%;
        margin-top: 2%;
        padding: 5px;
        margin-bottom: 1%;
    }

    .dark-top-card {
        bottom: -44px;
        left: 0;
        right: 0;
        width: 98%;
        margin: auto;
        position: absolute;
        padding: 0px;
    }

    .top-grid {
        top: 100px;
    }

    .margin-top {
        margin-top: 5%;
    }

    .slide-img {
        margin-top: 0vh !important;
        margin-bottom: 0vh !important;
    }

    .btn {
        width: 40% !important;
    }

    .checkbx-div {
        margin-top: -1%;
    }

    .background-holder {
        background: #128777;
        border-radius: 0 0 32px 32px;
        height: 227px;
        max-height: 100%;
    }

    .reject-booking-grid {
        width: 98%;
        margin: auto;
        margin-top: 2%;
    }

    .dark-reject-booking-grid {
        width: 98%;
        margin: auto;
        margin-top: 2%;
    }

    .dark-total-label-m {
        margin-bottom: 1%;
    }

    .dark-revenue-label {
        margin-bottom: 1%;
        margin-top: 1%;
    }

    .right-reject-count {
        position: inherit;
        color: red;
    }

    .table-m-title {
        margin: 2.2vh !important;
        margin-top: 4vh !important;
        margin-bottom: 4vh !important;
    }

    .missing-image-grid {
        width: 98%;
        margin-bottom: 3vh !important;
    }

    .table-dash {
        margin-top: 1%;
    }

    .m-title {
        margin: 25px 15px 0px;
    }

    .progressbar-holder {
        width: 250px;
        height: 250px;
        margin-left: 2%;
    }

    .total-label-m {
        font-size: 16px;
        margin-bottom: 1%;
    }

    .revenue-label {
        font-size: 16px;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .revenue-div {
        padding: 5px 7px;
        border-radius: 12px;
    }

    .revenue-div .total-label {
        padding-left: 4px;
        padding-top: 3px;
    }

    .unread-badge {
        margin-left: 1%;
    }

    .search-holder {
        width: 99%;
        margin: 0 auto;
    }

    .sort-btn {
        Width: 100%;
    }

    .booking-id-label {
        width: 85px;
        padding: 3px 5px 2px 5px;
    }

    .dark-booking-id-label {
        width: 85px;
        padding: 3px 5px 2px 5px;
    }

    .overall-list {
        width: 98%;
        margin-top: 1%;
        height: 100%;
    }

    .landscape-background-img-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .background-img-container {
        display: none;
    }

    .segment-width {
        width: 98%;
    }

    .modified-chip {
        margin-left: 20px;
        width: 120px;
    }

    .center-div {
        width: 75%;
        margin: auto;
        margin-bottom: 2%;
    }

    .accordion-content.show {
        width: 100%;
        margin: auto;
    }

    .btn-row {
        padding: 10px 0px;
    }

    .confirmed-chip {
        margin-left: 20px;
        width: 120px;
    }

    .popup-confirmed-chip {
        margin-left: 20px;
        width: 120px;
    }

    .popup-modified-chip {
        margin-left: 20px;
        width: 120px;
    }

    .no-image-vd {
        width: 300px;
        height: 200px;
        margin: 0;
        margin-left: 20px;
        object-fit: cover;
    }

    .product-detail-img {
        width: 300px;
        height: 320px;
        margin: 0;
        object-fit: cover;
    }

    .slider-holder .card {
        height: unset;
    }

    .slide-content {
        width: 100%;
    }

    .status3-card {
        box-shadow: none;
        width: 98%;
        margin-top: 5px !important;
        margin: auto;
        padding: 0;
    }

    .popup-modified-chip {
        width: 120px;
        margin-left: 20px;
    }

    .model-container {
        width: 99%;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .checkbx-div {
        margin-left: 6px;
    }

    .checkbx-label {
        margin-left: 10px;
        margin-top: 4px;
    }

    .payment-details-card {
        margin: 3px 0px;
    }

    .search-holder {
        width: 99%;
    }

    .segment-width {
        width: 97%;
    }

    .send-money-holder {
        width: 98%;
        margin: 0 auto;
    }

    .contact-holder .recip-card {
        height: 160px;
        padding: 20px 0 10px;
        min-width: 140px;
    }

    .empty-div {
        border: 1px solid #F0F0F0;
        margin-top: 30px !important;
        margin-bottom: 20px !important;
        margin: auto;
        width: 98%;
    }

    .dark-empty-div {
        border: 1px solid #F0F0F0;
        margin-top: 30px !important;
        margin-bottom: 20px !important;
        margin: auto;
        width: 98%;
    }

    .list-holder {
        width: 99%;
        margin: 0 auto;
    }

    .image-containers {
        width: 100%;
        height: 180px;
    }

    .profile-add {
        position: absolute;
        top: 30%;
        right: 46%;
    }

    .profile .profile-top-card .img-holder .details p {
        margin-top: 0;
    }

    .ahs-img {
        width: 40%;
        margin: auto;
    }

    .ahs-w-95 {
        width: 80%;
    }

    .ahs-title {
        text-align: center;
    }

    .status-chip {
        padding: 3px 15px;
        width: fit-content;
        margin: auto;
        margin-bottom: 20px;
    }

    .dark-mode-bg {
        background: #F8F9F9;
        margin-top: 4% !important;
    }

    .bottom-section {
        margin-top: 20px;
        padding: 0 25px;
    }

    .bottom-section .general {
        margin-top: 20px;
    }

    .change-password-img {
        width: 100px;
        margin: auto;
        margin-top: 2%;
    }

    .date-label {
        width: 30%;
    }

    .change-password-label {
        text-align: center;
        display: block;
        margin: 20px 20px 0px 20px;
    }

    .v-profile-td-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    .v-profile-last-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    .dark-v-profile-td-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    .dark-v-profile-last-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    .service-cat-chip {
        width: 100%;
    }

    .notification-img {
        margin: auto;
    }

    .notification-msg {
        -webkit-line-clamp: 2;
    }

    .notification-msg-general {
        -webkit-line-clamp: 1;
    }

    .service-title-pwa {
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        width: 240px;
        height: 50px;
    }

    .service-swiper {
        margin-right: 0px !important;
    }
    .no-service-swiper {
        margin-right: 10px !important;
    }

    .card-style {
        margin-top: 3% !important;
        width: 80%;
    }

    .dark-card-style {
        margin-top: 3% !important;
    }

    .dark-item-mode-bg {
        margin-top: 3% !important;
    }

    .capture-option {
        display: none;
    }

    .dark-contact-holder {
        display: flex;
        width: 100%;
    }

    .dark-add-product {
        display: flex;
        width: 50%;
    }

    .contact-holder {
        display: flex;
        width: 100%;
    }

    .add-product {
        display: flex;
        width: 50%;
    }

    .add-btn-holder {
        width: 100%;
    }

    .image-container-add {
        width: 100%;
        height: 195px;
    }

    .addProductImg {
        width: 100%;
        height: 100%;
    }

    .dark-contact-holder .dark-add-product {
        width: 100%;
        height: 195px;
    }

    .no-data-img {
        width: 30%;
        margin-top: 7% !important;
        margin-bottom: -7% !important;
        margin: auto;
    }

}

ion-datetime {
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 12px;
    margin-bottom: 3% !important;
    margin: auto;
    display: block;
    width: 100%;
    max-width: 100%;
}


ion-datetime::part(calendar-day) {
    border: 1px solid #12877733;
    border-radius: 12px;
    margin: 5px;
    padding: 20px;
}

.img-404 {
    width: 15%;
    text-align: center;
    display: block;
    margin: 1% 40%;
}

.card-404 {
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 50%;
    margin-top: 15% !important;
    margin: auto;
    border-radius: 10px;
    padding: 50px;
}

.h4-404 {
    font-family: Poppins;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
    color: #333333;
}

.p-404 {
    font-family: Poppins;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #333333;
}


.react-calendar {
    width: 100% !important;
    padding: 5px !important;
    margin: auto;
    box-shadow: 0px 5px 20px 0px #0000001A;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
    max-width: 100%;
    background: #fff !important;
    border: none !important;
    border-radius: 12px;
    font-family: 'Poppins' !important;
    line-height: unset;
    font-size: 16px !important;
}

abbr {
    text-decoration: none !important;
}

.react-calendar__tile {
    box-sizing: border-box;
    margin: 4px !important;
    padding: 0px 10px 0px 10px !important;
    border: 1px solid #12877733 !important;
    border-radius: 5px !important;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    color: #000000;
}

.react-calendar__month-view__days__day {
    flex-basis: calc(100% / 7 - 8px) !important;
}


.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year {
    flex-basis: calc(100% / 3 - 8px) !important;
}

.react-calendar__month-view__days__day--weekend {
    color: unset !important;
}

.day-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date-number {
    font-size: 16px;
    /* Adjust as needed */
    /* Add more styles if necessary */
}

.indicators {
    display: flex;
    justify-content: center;
}

.upcoming-booking-indicator {
    width: 10px;
    height: 10px;
    background-color: #ffd600;
    /* Color for upcoming bookings */
    border-radius: 50%;
    margin: 2px;
}

.react-calendar__tile--now {
    background: #ffff7600;
    color: #0000ff;
}

.completed-booking-indicator {
    width: 10px;
    height: 10px;
    background-color: #128777;
    /* Color for completed bookings */
    border-radius: 50%;
    margin: 2px;
}

.upcoming-booking {
    background-color: #ffd600 !important;
}

.completed-booking {
    background-color: #128777 !important;
    color: #fff !important;
}

.react-calendar__tile--now-upcoming {
    background: #ffff7600;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #ffffa900;
    color: #000 !important;
}

.react-calendar__tile--hasActive {
    background: #76baff00;
}

/* .react-calendar__tile--active {
    background: none !important;
    color: #000 !important;
} */

.picker-toolbar.sc-ion-picker-md {
    height: 60px !important;
}

.react-calendar__navigation button {
    background: none;
    min-width: 44px;
    font-size: 17px;
    color: #000000;
    font-family: 'Poppins';
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa900;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e600;
}

.react-calendar__tile--active {
    background: #006edc00;
}

.wa-widget-send-button {
    right: 10px;
    top: 80%;
}

.wa-chat-bubble {
    bottom: 75px !important;
}

@media only screen and (max-width: 600px) {
    .wa-chat-box {
        bottom: 130px !important;
    }
}

/* Custom CSS for Wati Chat Widget */
@media (min-width: 768px) {

    /* Desktop view */
    .wati-chat-button {
        position: fixed;
        bottom: 70px;
        right: -20px;
        color: white;
        padding: 10px 20px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        z-index: 1000;
        /* Ensure it's on top */
    }

    .wati-chat-button img {
        margin-right: 10px;
    }

    .wati-chat-modal {
        display: none;
        position: fixed;
        bottom: 80px;
        right: 20px;
        width: 300px;
        background: white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        z-index: 1000;
    }

    .wati-chat-modal.active {
        display: block;
    }

    .wati-chat-modal .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
    }
}

/* Hide default Wati chat widget for desktop */
@media (min-width: 768px) {
    #wati-widget .wa-chat-box {
        display: none !important;
    }
}

@media (min-width: 1200px) {

    .dash-ahs-img {
        width: 98%;
        margin-top: 1%;
    }

    .list-card {
        margin-top: 3%;
    }

    .wa-chat-bubble {
        display: none !important;
    }

    .wa-widget-send-button {
        display: none !important;
    }
}


/* Whatsapp chat widget */
.w-widget {
    position: fixed;
    bottom: 12px;
    right: 20px;
    z-index: 999;
}

.wa-chat-box {
    padding: 32px 32px 32px;
    min-height: 220px;
}

.msgboxxlauncher {
    cursor: pointer;
}

.msgboxxlauncher img {
    width: 200px;
    margin-right: 10px;
}

.msgboxxlauncher_cta {
    display: flex;
    flex-direction: column;
    color: white;
}

.w-overlay1 {
    position: fixed;
    right: 15px;
    bottom: 80px;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    border-radius: 5px;
}

.msgboxxChatModal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 350px;
    text-align: center;
}

.msgboxxChatModal img {
    max-width: 100%;
    height: auto;
}

.w-close-icon {
    cursor: pointer;
    font-size: 40px;
    width: 30px;
    padding: 30px;
    height: 30px;
    bottom: 10px;
    right: 20px;
    display: flex !important;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: #075e54;
    color: #fff;
    border-radius: 50%;
    z-index: 1000;
}

.msgboxxchat-window {
    background-color: #fff;
    max-height: 100vh;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 40px rgb(0 0 0/16%);
    z-index: 9999;
    border-radius: 6px 6px 0 0;
    font-family: inherit;
}

.msgboxxchat-window__header {
    background-color: #075e54;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 10px 10px 0px;
    border-radius: 6px;
}

.msgboxxchat-window__header .msgboxx-title {
    margin: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 !important;
    font-size: 12px !important;
    text-align: left !important;
    line-height: 1.1 !important;
    letter-spacing: normal !important;
}

.msgboxxchat-window__messages__options {
    margin: 10px;
}

.qr-code_section {
    width: 250px;
    margin: auto;
}

.qr-code_section img {
    width: 100%;
    height: 100%;
}

.msgboxx-button {
    background-color: #25d366;
    color: #fff;
    border: none;
    font-size: 16px;
    border-radius: 6px;
    padding: 6px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.msgboxx-p {
    margin: 0 0 5px;
    display: flex;
    align-items: center;
}

.msgboxxchat-window__messages__message {
    display: flex;
    align-items: center;
    padding: 12px 30px;
}

.or_line {
    text-align: center;
    overflow: hidden;
    padding: 2px;
    font-size: 15px;
}

.or_line span {
    display: inline-block;
    position: relative;
}

.or_line span:before,
.or_line span:after {
    content: '';
    display: block;
    position: absolute;
    width: 999px;
    border: 1px solid #25d366;
    top: 50%;
}

.or_line span:before {
    right: 30px;
}

.or_line span:after {
    left: 30px;
}

.w-arrow {
    font-size: 15px;
    color: #25d366;
    margin: 0px 4px;
}

.w-arrow svg {
    width: 15px;
    height: 15px;
    fill: #25d366;
}

.msgboxx-button svg {
    width: 16px;
    height: 16px;
    margin: 0px 6px;
    fill: #fff;
}

.msgboxx-title svg {
    width: 16px;
    height: 16px;
    margin: -3px 5px;
    fill: #fff;
}

.w-overlay1,
.w-close-icon {
    display: none;
}

.w-active {
    display: flex;
}

.wa-chat-box-poweredby {
    display: none !important;
}