.div-list {
    display: flex;
    align-items: center;
}

.list-label {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.list-btn {
    --background: #ED1C241A !important;
    --border-radius: 30px !important;
    padding: 4px 10px 4px 10px;
}

.delete-label {
    color: #ED1C24 !important;
    font-family: 'poppins' !important;
    font-size: 13px !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.list-card {
    width: 100%;
    margin: auto;
    border-radius: 10px;
    padding: 2px;
    box-shadow: 0px 5px 20px 0px #0000001A;
}

.unavail-title {
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
}

.update-btn {
    --background: #128777;
    --color: #fff;
    width: 100%;
    margin: auto;
    --box-shadow: none;
    border-radius: 12px;
}

.nodate-card {
    padding: 40px;
}

.nodate-label {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #33333380;
    display: block;
}

.select-title {
    color: #333333;
    font-family: 'Poppins';
    font-size: 18px;

    text-align: left;
}

.highlight {
    background-color: #ffc107 !important;
    color: #000000 !important;
    border-radius: 50%;
}