.label-outline-model-fp-signup {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f9f9;
    z-index: 2;
    margin: -7px 0px -10px 25px;
    font-size: 12px;
    transition: font-size 0.2s;
    color: #262626;
}

.form-control-model-fp-signup {
    padding-right: 30px;
    margin: auto;
    padding: 13px 45px 13px 13px;
    display: block;
    width: 100%;
    border: 2px solid #E6E6E6CC;
    border-radius: 10px;
    background-color: #fff0;
    color: #262626;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    min-height: 30px;
}


.d-title {
    font-family: 'Poppins';
    font-size: 17px;
    text-align: center;
    display: block;
    color: #333;
}

.model-container-signup {
    position: relative;
    margin: auto;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    width: 97%;
}

.windows .d-title {
    font-size: 14px;
}

.windows .label-outline-model-fp-signup {
    font-size: 11px;
}