/* .input-container input[type="text"]::placeholder {
    font-size: 12px;
} */
.input-container {
    position: relative;
    margin: 20px;
}

.main-title {
    font-family:  'PoppinsSemiBold';
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #262626;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    background-color: #F8F9F9;
}

.label-outline {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #F8F9F9;
    padding: 0 5px;
    font-size: 12px;
    transition: font-size 0.2s;
    color: #262626;
}

.m-title {
    margin: 15px 23px;
    font-size: 15px;
}

.form-control:not(:placeholder-shown)+.label-outline {
    font-size: 13px;
    background-color: #F8F9F9;
    margin: -9px 0px 0px 10px;
    font-weight: 500;
}

.mt-0 {
    margin-top: 0px !important;
}



.contact-holder .add-product {
    border-radius: 16px;
    display: inline-block;
    height: unset;
    color: #515151;
    padding: 40px;
    margin: auto;
    background-color: #F0F0F0;
    width: 92%;
    box-shadow: 0px 0px 4px 0px #0000001A;
}

.camera-btn-icon {
    width: 35%;

}

.plus-btn-icon {
    width: 40%;
}

.mt-2 {
    margin-top: 2vh !important;
}

.add-btn-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-btn {
    width: 93%;
    margin: auto;
    margin-bottom: 4% !important;
}


/* Dark Mode CSS Starts */

.dark-main-title {
    font-family:  'poppins';
    font-size: 14px;
      
    line-height: 22px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #CCCCCC;
}

.dark-form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #404040;
    border-radius: 10px;
    background-color: #212124;
    color: #cccccc;
}

.dark-form-control:not(:placeholder-shown)+.dark-label-outline {
    font-size: 13px;
    background-color: #212124;
    margin: -9px 0px 0px 10px;
    font-weight: 500;
}

.dark-label-outline {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #212124;
    padding: 0 5px;
    font-size: 12px;
    transition: font-size 0.2s;
    color: #CCCCCC;
}

.dark-contact-holder .dark-add-product {
    border-radius: 16px;
    display: inline-block;
    height: unset;
    padding: 40px;
    margin: auto;
    background-color: #262626;
    width: 92%;
    box-shadow: 0px 0px 4px 0px #0000001A;
}

.dark-contact-holder {
    margin-top: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    white-space: nowrap;
}

/* Dark Mode CSS Ends */