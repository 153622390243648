.label-control {
    width: 100%;
    padding: 15px;
    display: block;
    border: 1px solid #12877733;
    border-radius: 10px;
    background-color: #fff;
    color: #333333;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: left;
    min-height: 25px;
}

.day-title {
    color: #000000;
    font-family: 'Poppins';
    font-size: 16px;

    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: left;
}

.avail-list {
    width: 100%;
    border-radius: 10px;
    padding: 2px;
    box-shadow: 0px 5px 20px 0px #0000001A;
    margin: 0px 0px 3px 0px;
}


.picker-toolbar {
    justify-content: center !important;
    margin: 15px 0px 0px;
}

.picker-toolbar button {
    flex: 0 0 auto;
    /* This prevents the buttons from stretching */
}

.custom-picker-class .picker-toolbar {
    justify-content: center !important;
}

.custom-picker-class .picker-toolbar button {
    flex: 0 0 auto;
}


ion-picker {
    --ion-color-success: #28a745;
    /* This sets the green color for the 'success' state */
}

/* Set the color for the cancel button */
ion-picker {
    --ion-color-danger: #dc3545;
    /* This sets the red color for the 'danger' state */
}


/* You might need to use !important to override default styles */
.cancel-button {
    background: #FFFFFF !important;
    color: var(--ion-color-danger, #dc3545) !important;
    min-height: 42px;
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    --box-shadow: none;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #ED1C24 !important;
    border-radius: 10px;
    margin-right: 2px !important;
}

.confirm-button {
    background-color: #28BA62 !important;
    color: #fff !important;
    min-height: 42px;
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    --box-shadow: none;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #28BA62 !important;
    border-radius: 10px;
    --border-radius: 7px;
    margin-left: 2px !important;
}

.col-d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.avail-icon {
    width: 40%;
}

.plus-reject-btn {
    min-height: 45px;
    width: 100% !important;
}

.slots-title {
    margin: 15px 23px;
    font-size: 15px;
}

.date-grid {
    border-bottom: 1px solid #12877733;
    width: 95%;  
}

.date-ion-select {
    border: 2px solid #12877733;
    border-radius: 12px;
    padding: 0px 0px 0px 10px;
    background: #fff;
}

.label-outline-model-avail {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
    padding: 5px 10px 5px 10px;
    margin: -10px 0px -10px 13px;
    transition: font-size 0.2s;
    border-radius: 10px;
    color: #128777;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
}

.native-wrapper {
    width: 95% !important;
}

.last-btn-grid {
    background: #3333330F;
    border-radius: 10px;
    padding: 10px;
}

.last-btn-grid-title {
    text-align: center;
    font-size: 18px;
    display: block;
    margin: 10px 0px 0px 0px;
    font-family: 'PoppinsSemiBold';
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #262626;
}

.last-btn-grid-sec-title {
    text-align: center;
    display: block;
    margin: 10px 0px 0px;
}


.unavail-btn {
    border: 1px solid #333333;
    margin: 10px 0px 10px 0px;
    --border-radius: 12px;
    min-height: 50px;
    width: 100%;
    --background: #fff;
    --color: #333333;
    font-size: 16px;
    --box-shadow: none;
    border-radius: 12px;
}

.new-list-label {
    font-family: 'PoppinsSemiBold';
    font-size: 14px;
    color: #000000;
    --background: #d4e8e4;
    border-radius: 20px;
    /* padding: 10px 0px 10px 10px; */
    margin: 5px 0px 0px 10px;
    display: block;
}

.X {
    color: #ed1c24;
    margin: 0;
    cursor: pointer;
    font-size: 22px;
}

.X:hover {
    color: #ff787e;
}