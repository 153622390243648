.custom-segment-button-unconfirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #333333;
    --background-checked: #FFD6001A;
    --color-checked: #FFD600;
    --indicator-height: 4px;
    font-family: 'poppins' !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
}

.custom-segment-button-confirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #333333;
    --background-checked: #0F80E91A;
    --color-checked: #0F80E9;
    --indicator-height: 4px;
    font-family: 'poppins' !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
}

.custom-segment-button-expired {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #333333;
    --background-checked: #28BA621A;
    --color-checked: #28BA62;
    --indicator-height: 4px;
    font-family: 'poppins' !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
}

.label-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    display: inline-block !important;
}

ion-list {
    --background: #f8f9f9;
}

.booking-id-chip {
    background: #0F80E91A;
    padding: 4px 10px 4px 10px;
    border-radius: 12px;
    color: #0F80E9;
    font-size: 13px;
     
    font-family: 'Poppins';
}

.unconfirmed-chips {
    background: #FFD60033;
    color: #333333;
    padding: 4px 10px 4px 10px;
    border-radius: 12px;
    font-family: 'Poppins';
      
    font-size: 13px;
}

.modified-chips {
    background: #28BA621A;
    color: #28BA62;
    padding: 4px 10px 4px 10px;
    border-radius: 12px;
    font-family: 'Poppins';
      
    font-size: 13px;
}

.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: -12px;
    left: -12px;
}

.ribbon {
    font-size: 9px;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 5px 0;
    top: 19px;
    left: -32px;
    width: 120px;
    background-color: #FF8368;
    color: #fff;
}

.ribbon-modified {
    font-size: 10px;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 5px 0;
    top: 19px;
    left: -32px;
    width: 120px;
    background-color: #FEF6CD;
    color: #515151;
}

.booking-img {
    --border-radius: 0%;
    width: 80px;
    height: 80px;
    position: relative;
    --border-radius: 5px;
    border: 1px solid #12877733;
}

.booking-id-label {
    float: right;
    background-color: #56606B;
    color: #fff;
    width: 27%;
    padding: 3px 3px 2px 2px;
    border-radius: 6px;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
}

.postcode-label {
    color: #16C1D0;
    text-decoration: underline;
    font-family: 'Poppins';
    font-weight: 500;
}

.pound {
    color: #28BA62;
    font-family: 'Poppins';
     
    font-size: 16px;
}

.postcode-col {
    right: 0;
    left: 100%;
    position: sticky;
}

.first-title {
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins';
     
    letter-spacing: 0.4px;
    line-height: 15px;
}

.cat-subcat-title {
    color: #128777;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.4px;
    text-align: left;
}

.first-div {
    padding: 9px 0px 0px 0px;
}

.second-col {
    padding: 3px 0px 0px 10px;
}

.text-ellipis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.overall-list {
    width: 100%;
    margin: 0px 2px 0px 2px;
    border-radius: 10px;
    padding: 2px;
    box-shadow: 0px 5px 20px 0px #0000001A;
}

hr {
    background-color: #1287771A;
    width: 90%;
    border-bottom: 1px solid #1287771A;
}

.label-font-size {
    font-size: 14px;
}

.label-align {
    display: inline;
    vertical-align: middle;
}

.img-align {
    vertical-align: middle;
}

.middle-label {
    padding: 4px 0px 0px 4px;
    font-size: 13px;
    font-weight: 500;
    color: #515151;
    font-family: 'Poppins';
}

.call-icon {
    margin: -5% 2% 0% 0%;
    float: right;
}

.cat-person-icon {
    width: 8%;
}

.div-align {
    display: inline-block;
    line-height: 18px;
}

.expenses-bar {
    --background: #f8f9f9;
}

.booking-details-btn {
    --background: #128777;
    background: #128777;
    color: #fff !important;
    min-height: 42px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;
     
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #128777;
    border-radius: 10px;
    --border-radius: 12px;
}

.accept-btn-bkng {
    --background: #28BA62;
    background: #28BA62;
    color: #fff !important;
    min-height: 42px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #28BA62;
    border-radius: 10px;
    --border-radius: 7px;
}

.reject-bkg-btn {
    --background: #FFFFFF;
    color: #ED1C24 !important;
    min-height: 42px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;
     
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #ED1C24;
    border-radius: 10px;
    --border-radius: 12px;
}

.spanCount {
    background-color: #128777;
    color: #fff;
    max-width: 33px;
    font-size: 12px;
    display: flex;
    padding: 3px 6px 2px;
    border-radius: 6px;
    justify-content: center;
    margin: -4px 0px 0px 4px;
    align-items: center;
}

.light-mode-segements {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
}

.expenses {
    --background: #f8f9f9;
}

.top-item {
    --background: transparent;
}

.top-item .start-slot p {
    color: #77869e;
    font-family: 'Heavy';
    font-size: 0.9rem;
    margin: 0;
}

.top-item .start-slot h1 {
    color: #042c5c;
    font-family: 'Black';
    margin: 10px 0 0 0;
}

.top-item .end-slot p {
    font-family: 'Heavy';
    margin: 5px 0;
}

.top-item .end-slot #up {
    color: #1bc773;
}

.top-item .end-slot #down {
    color: #f24750;
}

.date-time-color {
    color: #000000;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: left;
}

.sort-btn {
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(18, 135, 119, 0.2), rgba(18, 135, 119, 0.2)), linear-gradient(0deg, #FFD600, #FFD600);
    border: 2px solid #12877733;
    box-shadow: 0px 0px 1px 0px #0000001A;
}

.postponed-batch {
    position: absolute;
    bottom: -10px;
    background: #007BFF;
    font-size: 11px;
    color: #FFFFFF;
    width: 80px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    padding: 2px;
    border-radius: 0px 0px 10px 10px;
}

.popup-ion-item {
    --background: #FFFFFF !important;
    margin: 0;
}

.popup-label {
    font-family: 'poppins' !important;
    font-size: 17px !important;
    font-weight: 500 !important;
}




/* Dark Mode CSS Starts */

.dark-sort-btn {
    background-color: #262626;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 10px;
}

.dark-custom-segment-button-unconfirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #FAEBE8;
    --color-checked: #D03716;
    font-family: 'poppins' !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC !important;
}

.dark-custom-segment-button-confirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #DCE9CD;
    --color-checked: #529003;
    font-family: 'poppins' !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC !important;
}



.dark-custom-segment-button-expired {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #E6E6E6;
    --color-checked: #808080;
    font-family: 'poppins' !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC !important;
}


.dark-overall-list {
    width: 95%;
    margin: auto;
    margin-top: 2%;
    border-radius: 10px;
    padding: 2px;
    box-shadow: none;
    background-color: #262626;
}

.dark-first-title {
    font-size: 16px;
    color: #CCCCCC;
    font-family: 'Poppins';
     
    letter-spacing: 0.4px;
    line-height: 15px;
}

.dark-middle-label {
    padding: 4px 0px 0px 4px;
    font-size: 13px;
    font-weight: 500;
    color: #CCCCCC;
    font-family: 'Poppins';
}

.dark-booking-id-label {
    float: right;
    background-color: #F0F0F0;
    color: #515151;
    width: 27%;
    padding: 3px 3px 2px 2px;
    border-radius: 6px;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}


.dark-date-time-color {
    color: #CCCCCC;
    font-family: 'Poppins';
    font-weight: 500;
}

.dark-hr {
    background-color: #D9D9D914 !important;
}

.dark-popup-ion-item {
    --background: #212124 !important;
    margin: 0;
    color: #CCCCCC;
}

.sort-count-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #128777;
    font-weight: bold;
    border-radius: 50px;
    color: #fff;
    max-width: 16px;
    font-size: 10px;
    display: flex;
    padding: 1px 6px 0px;
    justify-content: center;
    margin: -4px -8px 0px 0px;
    align-items: center;

}

/* Dark Mode CSS Ends */