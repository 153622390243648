.card-style {
    font-family:  'poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    padding: 10px;
    margin: auto;
    margin-top: 10% !important;
    border-radius: 12px;
    color: #515151;
    background-color: #F0F0F0;
    box-shadow: 0px 0px 20px 0px #00000026;
}

.date-label {
    background-color: #E8F9FA;
    padding: 10px;
    border-radius: 20px;
    margin: auto;
    display: block;
    width: 60%;
    text-align: center;
}

.dark-date-label {
    background-color: #262626;
    padding: 10px;
    border-radius: 20px;
    margin: auto;
    display: block;
    width: 60%;
    text-align: center;
    color: #fff;
}

.dark-card-style {
    box-shadow: none;
    font-family:  'poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: justify;
    background: #262626;
    width: 100%;
    border-radius: 12px;
    margin-top: 10% !important;
    padding: 10px;
    margin: auto;
    color: #CCCCCC;
}