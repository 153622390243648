
.successmodal-h2 {
    color: #13182b !important;
    font-size: 16px;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 400;
    margin: auto;
}

@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes drawCircle {
    0% {
        stroke-dashoffset: 151px;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes drawCheck {
    0% {
        stroke-dashoffset: 36px;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#successAnimationCircle {
    stroke-dasharray: 151px 151px;
    stroke: #28BA62;
}

#successAnimationCheck {
    stroke-dasharray: 36px 36px;
    stroke: #28BA62;
}

#successAnimationResult {
    fill: #28BA62;
    opacity: 0;
}

#successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;
}

#successAnimation.animated #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}

#successAnimation.animated #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}

#successAnimation.animated #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
}