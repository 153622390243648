.skip-button {
    color: #262626 !important;
    font-family:  'poppins';
    font-size: 14px;
      
    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: left;
}

.main-row {
    height: 100% !important;
    width: 100% !important;
}


.intro .img-holder img {
    height: 100vw;
    margin-top: 20vw;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #128777 !important;
}


.swiper-pagination-bullet {
    background: #7f7f7f !important;
}

.swiper {
    height: 100% !important;
}

.slide-img {
    max-width: 100%;
    width: 49%;
    margin: auto;
    display: block;
    margin-top: 7vh !important;
    margin-bottom: 7vh !important;
}

.bottom-holder {
    background: white;
    bottom: 0;
    z-index: 20;
}


.slide-content .sm-detail {

    color: #77869E;
    font-family: 'Medium';
    font-size: 14px;
    margin-top: 10px !important;
    padding-bottom: 20px !important;
}


.intro-h4 {
    font-family:  'poppins';
    font-size: 16px;
      
    line-height: 40px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #515151;
}

.intro-msg {
    font-family:  'poppins';
    font-size: 12px;
    font-weight: 500;
    color: #515151;
}

.btns-holder {
    margin: 0 10px;
    padding-bottom: 10px !important;
}

.next-btn {
    --background: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
      
    color: #128777;
    --box-shadow: none;
    border: 2px solid #128777;
}

.btn {
    --border-radius: 10px;
    display: inline-block !important;
    height: 3rem !important;
    margin: 0vh 5vh -10vh 0vh !important;
    text-transform: none;
    width: 100% !important;
    border-radius: 10px;
}

#signup {
    --background: transparent;
    --border-color: #77869E;
    --border-size: 0.1px;
    --border-style: solid;
    --color: #77869E;
}


/* Dark Mode CSS Starts */
.dark-skip-button {
    color: #CCCCCC !important;
    font-family:  'poppins';
    font-size: 14px;
      
    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: left;
}

.dark-intro-h4 {
    font-family:  'poppins';
    font-size: 16px;
      
    line-height: 40px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #CCCCCC;
}

.dark-intro-msg {
    font-family:  'poppins';
    font-size: 12px;
    font-weight: 500;
    color: #CCCCCC;
}

/* Dark Mode CSS Ends */