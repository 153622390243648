.service-cat-chip-view {
    background-color: #E8F9FA;
    color: #515151;
    font-family:  'poppins';
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #16C1D0;
    padding: 5px;
    border-radius: 60px;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}