.profile-toolbar {
    --border-width: 0;
    --background: #0047CC;
    --color: white;
}

.profile {
    --background: #F8F9F9;
}

.profile .profile-top-card {
    padding-bottom: 1px !important;
}

.profile .profile-top-card .img-holder {
    padding-top: 10px;
}

.profile-header-toolbar {
    --background: #18c1d0 !important;
}

.thumb {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 0px;
    margin: 0;
}

.border-bottom {
    border-bottom: 1px solid #0000001A;
}

.w-100-m-auto {
    width: 100%;
    margin: auto;
}

.general-title {
    font-family: 'Poppins';
    font-size: 20px;

    line-height: 30px;
    text-align: left;
    color: #333333;
    margin: 15px 0px;
    display: block;
}

.profile-label {
    font-family: 'poppins' !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #333333 !important;
    margin-left: 3%;
    overflow: unset !important;
}

.app-version {
    color: #33333380;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    display: block;
    margin: 15px 0px;
}

.dark-mode-profile-label {
    font-family: 'poppins' !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #FFFFFF !important;
    margin-left: 3%;
}

ion-toggle {
    --handle-background-checked: #FFFFFF !important;
    --background-checked: #C5C5C54D !important;
    --track-background-checked: #28BA62;
    --handle-box-shadow: 0px 1px 2px 0px #00000040;
    --track-background: #C5C5C5;
}

.profile .profile-top-card .img-holder ion-avatar {
    margin: 0 auto;
    --border-radius: 50px;
    width: 90px;
    height: 90px;
    position: relative;
}


.profile-top-card .badge-holder {
    margin: 0 20px;
    background: #f8f9f9;
    border-radius: 12px;
    padding: 10px 0;
}

.dark-mode-bg {
    --background: #128777;
    margin-top: 10% !important;
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 90%;
    margin: auto;
    border-radius: 10px;
}

.profile-top-card {
    background: white;
    border-radius: 12px;
    margin: auto;
    top: 1vh;
    width: 100%;
    position: relative;
    padding: 0px;
    box-shadow: 0px 5px 20px 0px #0000001A;
}

.profile-top-card .badge-holder .badge {
    display: inline-block;
    margin: 0 5px;
}

.bottom-section {
    margin-top: 30px;
}

.bottom-section .general {
    margin-top: 30px;
}

.bottom-section h5 {
    color: #333333;
    font-family: 'Poppins';
    font-size: 20px;

    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: left;
}

.width100 {
    width: 100%;
    padding: 0;
    margin: 0;
}

.mail-to {
    width: 100%;
    text-decoration: none;
}


.toggle-icon {
    background: #C5C5C54D !important;
}


/* Dark Mode CSS Stats */

.dark-profile {
    --background: #212124 !important;
}

.dark-profile-top-card {
    background: #262626 !important;
}

.dark-name-label {
    color: #CCCCCC !important;
    font-family: 'poppins' !important;
    font-size: 20px;

    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
}



.dark-item-mode-bg {
    --background: #212124;
    margin-top: 10% !important;
}

.dark-profile-label {
    font-family: 'poppins' !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #CCCCCC !important;
    margin-left: 3%;
}

.dark-email-label {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #CCCCCC;
}

.dark-bottom-section {
    color: #CCCCCC !important;
}

.ahs-w-95 {
    width: 95%;
    margin: auto;
    margin-bottom: 2% !important;

}

.ahs-title {
    font-family: 'Poppins';
    font-size: 20px;

    line-height: 30px;
    text-align: left;
    color: #333333;
    margin: 0px 15px 15px;
    display: block;
}

.dark-bottom-section .general .gen-list ion-item {
    border-radius: 12px;
    margin-top: 0px;
    --background: #262626;
}

.dark-bottom-section .general .gen-list ion-item ion-label h2 {
    color: #042c5c;
}

.dark-bottom-section .general .gen-list ion-item ion-label p {
    color: #77869e;
    font-family: 'Roman';
}

.dark-bottom-section {
    margin-top: 30px;
}

.dark-bottom-section .general {
    margin-top: 30px;
}


.dark-profile-ion-item {
    border-radius: 12px;
    margin-top: 1px;
    --background: #262626;
}

.profile-add {
    position: absolute;
    top: 35%;
    right: 40%;
}

.dark-action-sheet {
    --background: #212124;
    --button-color: #CCCCCC;
    --color: #CCCCCC;
}

.no-bg-ion-item {
    --background: #fff0;
}

.profile-card-bg {
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 12px;
    padding: 5px 0px;
}

.logout-card {
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 12px;
    padding: 0px;
    margin-top: 5%;
}

/* .clicked {
    background-color: #18c1d0;
    color: #fff;
} */
.mrgn-btn {
    margin-top: 5px !important;
}

.mrgn-btn-ahs {
    margin-top: 10px !important;
}


.user-name {
    color: #000000;
    font-family: 'Poppins';
    font-size: 19px;

    line-height: 26px;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 3%;
    display: block;
}

.user-mail {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #333333;
    margin-top: 1%;
    display: block;
}

.status-chip {
    background-color: #28BA621A;
    color: #28BA62;
    padding: 3px;
    width: 50%;
    border-radius: 20px;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    margin: auto;
    margin-bottom: 3%;
    margin-top: 3%;
}


ion-modal#example-modal-deleted {
    --max-height: 100%;
    --height: 350px;
    --width: 95%;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.sign-up-page {
    --background: #128777;
    background: #128777;
    color: #fff !important;
    min-height: 50px;
    font-family: 'Poppins';
    font-size: 15px;
    --box-shadow: none;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
    border: 2px solid #128777;
    border-radius: 14px;
    --border-radius: 12px;
}