.wallet-toolbar ion-title {
    color: #262626;
    font-family: 'Heavy';
    font-size: 19px;
}

.wallet-toolbar ion-title span {
    font-family: 'Roman';
    font-size: 13px;
}

.wallet-toolbar .end-btn {
    font-size: 30px;
}

.wallet {
    --background: #f8f9f9;
}


.slider-holder {
    margin-top: 20px;
    margin-bottom: 10px;
}

#primary {
    background: #f8f9f9;
    color: white;
}

#gray {
    background: #DFE7F5;

}

#gray p {
    color: black;
}

.slide-content {
    width: 100%;
}

.slider-holder .card {
    border-radius: 1rem;
    height: 50vw;
    width: 100%;
    font-family: 'Medium';
}

.slider-holder .card .card-logo-holder {
    padding-top: 2vh;
}

.card-logo-holder img {
    height: 5vh;
}

.card-number-holder p {
    font-family: 'Courier';
    font-size: 16px;
    margin-bottom: 0;
}

.card-details p {
    font-family: 'Courier';
    margin-bottom: 0;
}

.progress {
    margin-top: 10px;
}

.progress ion-label {
    color: #042C5C;
    font-family: 'Heavy';
    font-size: 13px;
}

.progress ion-progress-bar {
    --progress-background: #4DF1A1;
    --border-radius: 2.5px;
    --buffer-background: #F24750;
    margin-top: 10px;
}



.statics ion-item {
    --background: transparent;
}

.statics p {
    font-family: 'Heavy';
    color: #77869E;
    font-size: 13px;
}

.statics h3 {
    font-family: 'Heavy';
    color: #042C5C;
    font-size: 16px;
}

.col-p0 {
    padding: 3px;
}

.send-money-holder {
    margin-top: 20px;
}

.send-money-holder .contact-holder {
    margin-top: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    white-space: nowrap;
}

.send-money-holder .contact-holder ::-webkit-scrollbar {
    display: none !important;
}

.contact-holder .recip-card {
    background: white;
    border: 1px solid #dfe7f5;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    display: inline-block;
    height: 130px;
    margin: 0 10px;
    color: #515151;
    padding: 10px 0;
    min-width: 120px;
}

.contact-holder .recip-card .add-btn-holder {
    height: 64px;
    margin: 0 auto;
    padding-top: 10px;
}

.contact-holder .recip-card .add-btn-holder img {
    height: 2.5rem;
}

.contact-holder .recip-card ion-avatar {
    margin: 0 auto;
}

.contact-holder .recip-card p {
    color: #77869e;
    font-family: 'Medium';
    font-size: 0.9rem;
    margin: 5px 0 0 0;
}

.slide-img-title {
    margin: 6px;
}


.header-icons {
    font-size: 24px !important;
}

.search-bar {
    --background: #fff;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    width: 100%;
    line-height: 15px;
    color: #515151;
    padding: 0px;
    border: 2px solid #12877733;
    box-shadow: 0px 0px 1px 0px #0000001A;
    border-radius: 7px;
    height: 40px;
}

.empty-div {
    border: 1px solid #F0F0F0;
    margin-top: 7% !important;
    margin-bottom: 2% !important;
    margin: auto;
    width: 90%;
}

.image-containers {
    width: 100%;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-containers img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-title-card {
    box-shadow: 0px 0px 1px 0px #00000040;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    color: #515151;
}

.text-ellipis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 132px;
    display: block;
}


.image-title {
    font-size: 18px;
    padding: 10px;
    font-family: 'Poppins';

    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #333333;
}

.scroll-image-title {
    font-size: 13px;
    padding: 5px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    font-family: 'Poppins';
}

.segment-width {
    width: 95%;
    margin: auto;
    display: block;
    margin-top: 2%;
}

.p-0 {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding: 0;
}

.p-add-title {
    padding-inline-start: var(--ion-padding, 14px);
}

.link-decoration {
    text-decoration: none;
}

.light-ion-title {
    color: #262626;
    font-family: 'Poppins';
    font-size: 20px;

    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: center;
}

.custom-segment-button {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #FAF6E0;
    --color-checked: #FFD600;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
    color: #000000;
}




/* Dark Mode CSS Starts */

.dark-ion-title {
    color: #CCCCCC;
    font-family: 'Poppins';
    font-size: 18px;

    line-height: 27px;
    letter-spacing: 0.4px;
    text-align: center;
}

.dark-search-bar {
    --background: #262626;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    width: 100%;
    line-height: 15px;
    color: #CCCCCC;
    box-shadow: none;
    padding: 0px;
}

.dark-custom-segment-button {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #262626;
    --color-checked: #16C1D0;
    font-family: 'poppins' !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC;
}

.dark-empty-div {
    margin-top: 7% !important;
    margin-bottom: 2% !important;
    margin: auto;
    border: 1px solid #D9D9D914;
    width: 90%;
}

.dark-image-title-card {
    box-shadow: 0px 0px 1px 0px #00000040;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #262626;
    color: #CCCCCC;
}

.dark-image-title {
    font-size: 13px;
    padding: 10px;
    font-family: 'Poppins';

    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #515151;
}

#darkPrimary {
    background: #212124;
    color: #212124;
}

/* Dark Mode CSS Ends */


.no-data-img {
    width: 55%;
    margin-top: 15% !important;
    margin: auto;
}

.add-product-btn {
    --background: #fff0;
    padding: 0;
    margin: 0;
    box-shadow: none;
}

.button-native {
    padding: 0 !important;
}



.product-img {
    --border-radius: 12%;
    width: 60px;
    height: 60px;
    position: relative;
}

.edit-icon-btn {
    float: right;
    right: 0;
    position: relative;
    left: 60%;
}

.edit-icon {
    font-size: 30px;
}

.style-first-title {
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins';
    letter-spacing: 0.4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: 250px;

}

.sub-cat-title {
    font-size: 12px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 15px;
    margin-top: 3%;
    display: block;
}


.my-price-title {
    font-size: 16px;
    color: #28BA62;
    font-family: 'Poppins';

    letter-spacing: 0.4px;
    line-height: 15px;
}

.fee-title {
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins';

    letter-spacing: 0.4px;
    line-height: 15px;
}

.net-price-title {
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins';

    letter-spacing: 0.4px;
    line-height: 15px;
}

.my-price-sub-title {
    font-size: 12px;
    color: #28BA62;
    font-family: 'Poppins';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 15px;
}


.fee-sub-title {
    font-size: 12px;
    color: #333333;
    font-family: 'Poppins';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 15px;
}

.net-price-sub-title {
    font-size: 12px;
    color: #333333;
    font-family: 'Poppins';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 15px;
}

.border-right {
    border-right: 2px solid #1287771A;
    font-size: 12px !important;
}

.mile-coverage-chip {
    background: #1287771A;
    padding: 4px 10px 4px 10px;
    border-radius: 12px;
    color: #128777;
    font-size: 12px;

    font-family: 'Poppins';
}

.action-div {
    display: flex;
    align-items: flex-start;
}

.toggleActive {
    margin-left: auto;
    /* Push to the right */
}

.free-miles-chip {
    margin: 0px 4px 0px -4px;
}

.edit-active-chip {
    margin: 0% 10% 0% -10%;
    background: #28BA621A;
    padding: 4px 10px 4px 10px;
    border-radius: 30px;
    color: #28BA62;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: center;
}

.edit-inactive-chip {
    margin: 0% 10% 0% -10%;
    padding: 4px 10px 4px 10px;
    border-radius: 30px;
    color: #ED1C24;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: center;
    background: #ED1C241A;
}

.service-title-status {
    color: #000000;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: left;
}

.edit-style-title {
    font-family: 'Poppins';
    font-size: 20px;

    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #000000;
}

.edit-cat-title {
    color: #128777;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: left;
}

.edit-subcat-title {
    color: #000000;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.4px;
    text-align: left;
}

.edit-model-container {
    position: relative;
    margin: auto;
    /* margin-top: 2% !important; */
    /* margin-bottom: 2% !important; */
    width: 100%;
}


.label-outline-model-edit {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f9f9;
    z-index: 2;
    margin: -7px 0px -10px 15px;
    font-size: 13px;
    transition: font-size 0.2s;
    color: #128777;
}

.input-pound-label {
    color: #333333;
    font-size: 16px;
}

.edit-cancel {
    color: #128777;
    font-family: 'Poppins';
    font-size: 16px;

    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: center;
    display: block;
    text-decoration: underline;
}

.form-control-model-upload {
    width: 100%;
    display: block;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff0;
    border: 2px solid #E6E6E6CC;
    color: #333333;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: left;
    min-height: 60px;
}

.btn-upload {
    position: absolute;
    right: 0px;
    width: 35%;
    top: 50%;
    font-size: 18px;
    font-family: 'Poppins';
    transform: translateY(-50%);
    cursor: pointer;
    background: #333333;
    color: #fff !important;
    min-height: 57px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.addProductImg {
    list-style: none;
    display: flex;
    width: 100%;
    height: 95%;
    object-fit: cover;
}

.image-container-add {
    width: 380px;
    height: 200px;
    max-height: 500px;
    overflow: scroll;
    border: 3px dotted #12877733;
    border-radius: 10px;
}

.image-container-add img {
    border-radius: 2px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.close-btn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 30%;
}

.style-title-div {
    padding: 11px 0px 0px 11px;
}

.services-btn {
    box-shadow: 0px 5px 20px 0px #0000001A;
    border-radius: 10px;
    text-align: center;
    display: block;
    margin: auto !important;
    padding: 20px;
    width: 100%;
    font-size: 16px;
    font-family: 'PoppinsSemiBold';
}


.service-dot-icon {
    width: 5%;
    margin-left: 4%;
}

.title-div {
    display: flex;
    align-items: center;
}

.serices-title {
    margin: 0;
    margin-left: 2% !important;
    font-size: 19px;
}

.status-title {
    margin: 0;
    float: right;
    right: 50px;
    top: 2px;
    position: absolute;
    color: #000000;
}

.list-p-5 {
padding: 5px;
}

.img-upload {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.label-outline-price {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f9f9;
    z-index: 2;
    margin: -5px 0px 0px 5px;
    font-size: 9px;
    transition: font-size 0.2s;
    color: #3333337d;
}

.form-control-model-price {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff0;
    border: 1px solid #12877733;
    color: #3333337d;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: left;
    min-height: 10px;
}

.service-img {
    width: 80%;
    height: 80%;
    object-fit: cover;
    border-radius: 3px;
}

.no-service-img{
    width: 80%;
    height: 80%;
}

.services-empty-div {
    border: 1px solid #0000004D;
    margin-top: 2% !important;
    margin-bottom: 3% !important;
    margin: auto;
    width: 100%;
}

.inactive-card {
    border-bottom: 2px solid #ED1C24;
    padding: 10px 1px !important;
}

.slide-content-services {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.active-card-p {
    padding: 5px 2px 2px 2px !important;
}

/* 
.popup-service-img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    object-fit: contain;
    display: block;
    margin: auto;
    border-radius: 20px;
}

ion-modal#example-modal-image-view {
    padding: 10px;
    --max-height: 100%;
    --height: 500px;
    --width: 100%;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.ion-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.ion-grid {
    width: 100%;
    height: 100%;
    padding: 0;
}

.ion-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
    overflow: hidden;
    border-radius: 20px; 
} */

.popup-service-img {
    clip-path: inset(0 round 20px);
    max-width: 500px;
    max-height: 400px;
  }

  .service-img {
    clip-path: inset(0 round 10px);
  }

  ion-modal#example-modal-image-view {
    padding: 200px 10px;
    max-height: 100%;
    display: flex;
    align-items: center;
    --border-radius: 20px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  
  ion-modal#example-modal-image-view h1 {
    margin: 20px 20px 10px 20px;
  }
  
  ion-modal#example-modal-image-view ion-icon {
    margin-right: 6px;
  
    width: 48px;
    height: 48px;
  
    padding: 4px 0;
  
    color: #aaaaaa;
  }
  
  ion-modal#example-modal-image-view .wrapper {
    margin-bottom: 10px;
  }
  